//#region Dependencias
import ButtonDesign from '@planeo-features/auth/login/Components/ButtonDesign';
import { Col, Form, Row } from 'react-bootstrap';
import { useHook } from '../Hooks/hook';
import Select from 'react-select';
import { doctypes } from '@planeo-services/logics/constants';
//#endregion

const FormPersonaEliminarCuenta = () => {
    //#region Hooks
    const hook = useHook();
    //#endregion

    const options = doctypes.map((x) => {
        return { label: x.name, value: x.id };
    });

    //#region Render
    return (
        <>
            <div className='planeo-signup-page-form' style={{marginTop: -50}}>
                <h2>
                    <strong>Elimina</strong> tu cuenta
                </h2>

                <div>
                    <p>
                        <strong className='font-18'>Diligencia el siguiente formulario para eliminar tu cuenta en Planeo</strong>
                    </p>
                </div>

                <hr />
            </div>

			<div style={{textAlign: 'left', justifyContent: 'center', alignContent: 'center', paddingLeft: 130, paddingRight: 110}}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label className='my-2'>Tipo documento *</Form.Label>
							<Select
                                classNamePrefix='planeo-select'
                                options={options}
                                id='documentType'
                                name='documentType'
                                placeholder='Selecciona tipo documento'
                                value={options.find((x) => x.value === hook.formik.values.documentType)}
                                onChange={(e)=>hook.formik.setFieldValue('documentType', e.value)}
                            />
                            <Form.Text className='text-danger'>{hook.formik.errors.documentType}</Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label className='my-2'>Documento *</Form.Label>
							<Form.Control
								id='document'
                                name='document'
								type='number'
								placeholder=''
								min='0'
								max='9'
                                style={{border: '1px solid #ced4da'}}
                                value={hook.formik.values.document}
                                onChange={hook.formik.handleChange}
							/>
                            <Form.Text className='text-danger'>{hook.formik.errors.document}</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='my-2'>
                    <Col xs={12} md={6}>
                        <Form.Group>
                            <Form.Label className='my-2'>Correo registrado *</Form.Label>
                            <Form.Control 
                                id='email'
                                name='email'
                                type='text'
                                placeholder=''
                                style={{border: '1px solid #ced4da'}}
                                value={hook.formik.values.email}
                                onChange={hook.formik.handleChange}
                            />
                            <Form.Text className='text-danger'>{hook.formik.errors.email}</Form.Text>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className='input-icon'>
                            <Form.Label className='my-2'>Contraseña *</Form.Label>
                            <Form.Control
                                id='password'
                                name='password'
                                placeholder=''
                                type='password'
                                autoComplete='off'
                                onChange={hook.formik.handleChange}
                                style={{border: '1px solid #ced4da'}}
                                value={hook.formik.values.password}
                            />
                            <Form.Text className='text-danger'>{hook.formik.errors.password}</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <div style={{textAlign: 'center'}}>
                    <ButtonDesign
                        titulo={'Eliminar mi cuenta'}
                        variant='outline-primary'
                        onClick={()=> hook.formik.handleSubmit()}
                        cargando={hook.formik.isSubmitting}
                        size='16px'
                    />
                </div>
                
			</div>
        </>
	);
    //#endregion
}

export default FormPersonaEliminarCuenta