//#region Dependencias
import {Form} from 'react-bootstrap';
//#endregion

const SelectDesign = ({
    titulo,
    id,
    onChange,
    error,
    disabled
}) => {
    //#region Render
    return (
        <Form.Group>
            <Form.Label style={{fontSize:'12px', marginTop: 30}}>{titulo}</Form.Label>
            <Form.Control
                id={id}
                as='select'
                isInvalid={!!error}
                onChange={onChange}
                style={{
                    border: '1px solid #0000001F'
                }}
                disabled={disabled}
            >
                <option value={0}>Selecciona uno:</option>
                <option value={13}>Cédula de ciudadanía</option>
                <option value={12}>Tarjeta de Identidad</option>
                <option value={41}>Pasaporte</option>
            </Form.Control>
            {
                error
                &&
                <Form.Control.Feedback type='invalid'>
                    {error}
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
    //#endregion
}

export default SelectDesign;