//#region Dependencias
import { NewTypes } from "../ActionTypes/Actions_Interfaces";
import { benchmark } from "./InitializerBenchmark";
import { documents } from "./InitializerDocuments";
import { notifications } from "./InitializerNotifications";
//#endregion

export const initialState : NewTypes = {
    sesionDocuments : documents,
    sesionNotifications : notifications,
    sesionBenchmark : benchmark
}