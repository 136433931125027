//#region Dependencias
import { BenchmarkAnalysisData, BenchmarkLiberarPeticionDto, BenchmarkList } from "@planeo-services/api/models/benchmarkModelsInterface";
//#endregion

export const resultCombination : BenchmarkAnalysisData = {
    benfitsMeans: [],
    compensationMeans: [],
    salaryMeans: [],
    marketBenefits: {
        q0: 0,
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0
    },
    marketCompensation: {
        q0: 0,
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0
    },
    marketSalary: {
        q0: 0,
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0
    },
    beneficios: []
}

export const releaseRequest : BenchmarkLiberarPeticionDto = {
    cargando : true
}

export const benchmark : BenchmarkList = {
    listAcademicBackgrounds: [],
    listAreas: [],
    listCompanySectors: [],
    listCountryStates: [],
    listExperienceRanges: [],
    listProfessions: [],
    resultCombination,
    releaseRequest
}