//#region Dependencias
import { useRef, useState } from 'react'
import { Container, Row, Col, Card, Spinner, Form } from 'react-bootstrap';
import '../Styles/styles.scss';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EstadoPagoDocumentModel, EstadoPagoDocumentRecaptchaModel } from '../Interfaces/ProjectT.models';
import SelectDesign from '../Components/SelectDesign';
import TextBoxDesign from '@planeo-features/auth/login/Components/TextBoxDesign';
import ButtonDesign from '@planeo-features/auth/login/Components/ButtonDesign';
import SelfEmployeesAPI from '@planeo-services/api/functions/SelfEmployeesAPI';
import environments from '@planeo-environments';
import { useHistory } from 'react-router-dom';
import { useFirebaseApp } from 'reactfire';
import ReCAPTCHA from 'react-google-recaptcha';
//#endregion

const ProjectTScreen = () => {
    //#region useRef
    const captcha = useRef<ReCAPTCHA>(null);
    //#endregion

    //#region Hooks
    const firebase = useFirebaseApp();
    const history = useHistory();
    //#endregion
    
    //#region useState
    const [signing, setSigning] = useState(false);
    const [mostrar, setMostrar] = useState(false);
    const [resultado, setResultado] = useState<boolean | undefined>(false);
    const [bloqueo, setBloqueo] = useState(false);
    //#endregion

    //#region Validacion datos
    const validationSchemaSignUp = yup.object({
        document_type: yup.number()
            .required('Tipo de documento no v\u00E1lido')
            .min(1, 'Tipo de documento requerido')
            .typeError('Tipo de documento no v\u00E1lido'),
        document: yup.string()
            .required('El número de documento es requerido')
            .max(15, 'El número de documento no es v\u00E1lido')
            .typeError('El número de documento no es v\u00E1lido'),
        captcha: yup.string().trim().required('El captcha requerido').nullable(true)
    });
    //#endregion

    //#region Formik
    const formik = useFormik<EstadoPagoDocumentRecaptchaModel>({
        initialValues: {
            document_type : 0,
            document : '',
            captcha: null
        },
        validationSchema: validationSchemaSignUp,
        validateOnChange: false,
        onSubmit: (formikValue) => {
            Fn_Consultar(formikValue);
        }
    });
    //#endregion

    //#region Funciones
    //#region Fn_Consultar
    const Fn_Consultar = async (formikValue: EstadoPagoDocumentModel) => {
        setSigning(true);
        setMostrar(true);

        try {
            const resultado = await SelfEmployeesAPI.Fn_ConsultaEstadoPagosPlanillasPorIdentificacion(formikValue, firebase, history);
            setResultado(resultado);
        } catch (error) {
            console.error('Error durante la consulta:', error);
        } finally {
            setSigning(false);
            setBloqueo(true);
        }
    }
    //#endregion
    //#endregion
    
    //#region Render
    return (
        <Container>
            <Row className='justify-content-center' style={{marginTop: 70}}>
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Header className='bg-primary text-white'>
                            <h1 className='text-center'>Buscador Proyecto T</h1>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <SelectDesign
                                        titulo='Tipo de documento *'
                                        id='document_type'
                                        key='document_type'
                                        error={formik.errors.document_type}
                                        onChange={formik.handleChange}
                                        disabled={bloqueo}
                                    />
                                </Col>
                                <Col>
                                    <TextBoxDesign
                                        id='document'
                                        cantidad={15}
                                        titulo='Número de documento *'
                                        key={'document'}
                                        tipo='text'
                                        error={formik.errors.document}
                                        onChange={formik.handleChange}
                                        disabled={bloqueo}
                                        defaultValue={''}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Form.Group
                                    style={{
                                        marginTop: 28,
                                        paddingTop: 20,
                                        paddingBottom: 20,
                                        textAlign: 'center'
                                    }}
                                >
                                    <ReCAPTCHA className='g-recaptcha' id='captcha' ref={captcha} sitekey={environments.firebaseConfig.captcha} onChange={(campo: string)=>formik.setFieldValue('captcha', campo)} />
                                    {
                                        formik.errors.captcha
                                        &&
                                        <>
                                            <br />
                                            <Form.Text className='text-danger'>{formik.errors.captcha}</Form.Text>
                                        </>
                                    }
                                </Form.Group>
                            </div>
                            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between'}}>
                                <div
                                    style={{paddingRight: 10, width: '50%'}}
                                >
                                    <ButtonDesign
                                        titulo={'CONSULTAR'}
                                        variant='outline-primary'
                                        onClick={()=> formik.handleSubmit()}
                                        cargando={false}
                                        size={14}
                                    />
                                </div>
                                <div
                                    style={{paddingLeft: 10, width: '50%'}}
                                >
                                    <ButtonDesign
                                        titulo={'NUEVA BÚSQUEDA'}
                                        variant='outline-primary'
                                        onClick={()=> {setBloqueo(false); setMostrar(false); setResultado(false);}}
                                        cargando={false}
                                        size={14}
                                    />
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

            {
                mostrar
                &&
                <Row className='justify-content-center' style={{marginTop: 30}}>
                    <Col xs={12} md={6}>
                        <Card>
                            <Card.Header>
                                <h1 className='text-center'>Resultados</h1>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        {signing 
                                            ? <Spinner animation="border" aria-live="polite" className="d-block mx-auto">
                                                <output className="visually-hidden">Loading...</output>
                                            </Spinner>
                                            : (
                                                <>
                                                    <h2
                                                        style={{
                                                            marginTop: 30,
                                                            fontSize: 18,
                                                            textAlign: 'center',
                                                            fontWeight: '700',
                                                            color: '#000'
                                                        }}
                                                    >Estado</h2>
                                                    <h2
                                                        style={{
                                                            marginTop: 5,
                                                            fontSize: 18,
                                                            textAlign: 'center',
                                                            fontWeight: '700',
                                                            color: resultado ? 'green' : 'red'
                                                        }}
                                                    >{resultado ? 'Pagos al día' : 'Pagos pendientes'}</h2>
                                                </>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </Container>
    )
    //#endregion
}

export default ProjectTScreen;