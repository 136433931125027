//#region Dependencias
import { ReactElement } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import LoginScreen from './login/Screens/LoginScreen';
import { PassWordLandingPage } from './password-recovery';
import { SignUpPage } from './signup';
import DeleteAccountScreen from './deleteAccount/Screens/DeleteAccountScreen';
import ProjectTScreen from './projectTSearch/Screens/ProjectTScreen';
//#endregion

export const AuthRoutes = (): ReactElement => {
	const { path } = useRouteMatch();

	return (
		<Switch>	
			<Route exact path={`${path}`} component={LoginScreen}></Route>
			<Route exact path={`${path}SignUp`} component={SignUpPage}></Route>
			<Route path={`${path}PassWordRecovery/action`} component={PassWordLandingPage}></Route>
			<Route path={`${path}DeleteAccount`} component={DeleteAccountScreen} />
			<Route path={`${path}ProjectSearchT`} component={ProjectTScreen} />
			<Redirect from='*' to={`${path}`} />
		</Switch>
	);
};
