//#region Dependencias
import { EstadoPagoDocumentModel } from "@planeo-features/auth/projectTSearch/Interfaces/ProjectT.models";
import { GetResultStatePaymentByIdentification_Post } from "../EndPoints/SelfEmployeesEndPoints";
import DocumentsAPI from "./DocumentsAPI";
//#endregion

const SelfEmployeesAPI = {
    //#region Fn_ConsultaEstadoPagosPlanillasPorIdentificacion
    Fn_ConsultaEstadoPagosPlanillasPorIdentificacion : async (body: EstadoPagoDocumentModel, firebase: any, history: any) => {
        return await GetResultStatePaymentByIdentification_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                return data.Resultado;
            }

            return false;
        })
        .catch(async (err) => {
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
            return false;
        });
    }
    //#endregion
}

export default SelfEmployeesAPI;