//#region Dependencias
import { ActionsGeneral } from "@planeo-providers/Context/ActionTypes";
import { GetAddSenderFolder_Post, GetBellsFolders_Post, GetCreateFileShared_Post, GetCreateFolderShared_Post, GetDeleteFileShared_Get, GetDeleteFolderShared_Post, GetDeleteSenderFolder_Post, GetListEmployeesByCompanyFilter_Post, GetListFoldersAndFiles_Post, GetListFoldersPrincipal_Get, GetListPeopleMailBox_Get, GetListReceiversByFolderId_Get, GetListReportFilesViewerEmployees_Post, GetListUsersByCompanyId_Get, GetUpdatePropertiesFileShared_Post, GetUpdatePropertiesFolderShared_Post, GetUpdateStateFile_Post, GetViewFileShared_Get } from "../EndPoints/DocumentsEndPoints";
import {Dispatch} from 'react';
import { DocumentsContadorIndependienteArchivosNuevosPropsModel, DocumentsCreateFileModel, DocumentsDeleteFolderModel, DocumentsDestinatariosActionsModel, DocumentsListFoldersAndFilesDto, DocumentsListFoldersAndFilesSubfoldersMiniDto, DocumentsListPeopleCompanyDto, DocumentsUpdatePropertiesFileModel, DocumentsUpdatePropertiesModel, DocumentsViewFileModel, EmployeeListByFilterModel, GestionEstadoNotasModel, ReportViewerFilesModel } from '../models/documentsModelsInterface';
import { documentsListFoldersAndFilesDto, documentsReportViewerFilesEmployeesModal } from "@planeo-providers/Context/Inicializadores/InitializerDocuments";
import { CreateFolderSharedFormModelComplete } from "@planeo-features/documents/documents-manager/partials/form-create-folder-shared/defs";
import { AlertDialogService } from "@planeo-components/alerts-dialog";
import { LeftSidePanelMenuOption } from "@planeo-features/documents/documents-manager/partials/left-side-panel";
import { TypeFolder } from "@planeo-features/documents/documents-manager/defs/tab-type.enum";
//#endregion

const DocumentsAPI = {
    //#region FnCatchExcepcionAuth
    FnCatchExcepcionAuth : async (err: any, firebase: any, history: any) => {
        if(err?.status === 401){
            await firebase.auth().signOut();
            localStorage.removeItem('verificationNotify');
            localStorage.removeItem('TokenAPI');
            history.push('/');
        }
        else{
            if(err?.data !== undefined){
                await AlertDialogService.alert({
                    title: 'Notificación del sistema',
                    message: err?.data?.Mensaje,
                    footer: undefined
                });
            }
        }
    },
    //#endregion
    //#region FnListarPersonas
    FnListarPersonas : (idEmpresa: string, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, visor: boolean = true) => {
        dispatch({
            type: 'LIST_FOLDERS_PRINCIPAL',
            payload: []
        });

        dispatch({
            type: 'LOADING_SIDEBAR',
            payload : {
                loading : true
            }
        });

        const itemsSalida : LeftSidePanelMenuOption[] = [];
        GetListPeopleMailBox_Get(idEmpresa)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                const respuestaPeticion : DocumentsListPeopleCompanyDto[] = data;
               
                dispatch({
                    type: 'LIST_PEOPLE_MAILBOX',
                    payload: respuestaPeticion
                });

                if(visor){
                    respuestaPeticion.forEach((item) => {
                        const {id, fullName, contador, document, documentType} = item;
                        const objetoPreparar : LeftSidePanelMenuOption = {
                            id,
                            name : fullName + ' + ' + documentType + '-' + document,
                            contador,
                            classification: 0,
                            folderProperties : null
                        }
        
                        itemsSalida.push(objetoPreparar);
                    })
    
                    dispatch({
                        type: 'LIST_FOLDERS_SIDEBAR',
                        payload : itemsSalida
                    });
                }
            }

            dispatch({
                type: 'LOADING_SIDEBAR',
                payload : {
                    loading : false
                }
            });

            dispatch({
                type: 'LOADING',
                payload : {
                    loading : false
                }
            });
        })
        .catch(async (err) => {
            dispatch({
                type: 'LIST_PEOPLE_MAILBOX',
                payload: []
            });

            dispatch({
                type: 'LOADING_SIDEBAR',
                payload : {
                    loading : false
                }
            });

            dispatch({
                type: 'LOADING',
                payload : {
                    loading : false
                }
            });
            
            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        });
    },
    //#endregion
    //#region FnListarCarpetasPrincipales
    FnListarCarpetasPrincipales : async (companyId: string, dispatch: Dispatch<ActionsGeneral>, cargaSidebar : boolean = true, firebase: any, history: any, time: number = 0, userId: string) => {
        dispatch({
            type: 'LIST_FOLDERS_PRINCIPAL',
            payload: []
        });
        
        if(cargaSidebar){
            dispatch({
                type: 'LOADING_SIDEBAR',
                payload : {
                    loading : true
                }
            });
        }

        const itemsSalida : LeftSidePanelMenuOption[] = [];
        return await GetListFoldersPrincipal_Get(companyId, userId)
        .then((respuesta) => {
            const dataOriginal = respuesta.data;
            
            dispatch({
                type: 'LIST_FOLDERS_PRINCIPAL',
                payload: dataOriginal
            });

            if(cargaSidebar){
                dataOriginal.forEach((item) => {
                    const {id, name, classification} = item;
                    const objetoPreparar : LeftSidePanelMenuOption = {
                        id,
                        name,
                        contador : 0,
                        classification,
                        folderProperties: item
                    }
    
                    itemsSalida.push(objetoPreparar);
                })
    
                dispatch({
                    type: 'LIST_FOLDERS_SIDEBAR',
                    payload : itemsSalida
                });
            }
            
            dispatch({
                type: 'LOADING_SIDEBAR',
                payload : {
                    loading : false
                }
            });

            setTimeout(() => {
                dispatch({
                    type: 'LOADING',
                    payload: {
                        loading : false
                    }
                });    
            }, time);

            return dataOriginal;
        })
        .catch(async (err) => {
            dispatch({
                type: 'LOADING_SIDEBAR',
                payload : {
                    loading : false
                }
            });  

            dispatch({
                type: 'LIST_FOLDERS_PRINCIPAL',
                payload: []
            });

            dispatch({
                type: 'LIST_FOLDERS_SIDEBAR',
                payload : []
            });

            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }

            return [];
        })
    },
    //#endregion
    //#region FnListarCarpetasCompartidas
    FnListarCarpetasCompartidas : (body: DocumentsDeleteFolderModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, cargaLoading: boolean = true, cargaEmpresarialView: boolean = false) => {
        if(cargaLoading){
            dispatch({
                type: 'LOADING',
                payload : {
                    loading : true
                }
            });
        }

        GetListFoldersAndFiles_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                const respuestaPeticion : DocumentsListFoldersAndFilesDto = data;

                dispatch({
                    type: 'LIST_FOLDERS_FILES',
                    payload: respuestaPeticion
                });

                dispatch({
                    type: 'LOADING',
                    payload : {
                        loading : false
                    }
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'LIST_FOLDERS_FILES',
                payload: documentsListFoldersAndFilesDto
            });

            if(cargaLoading){
                dispatch({
                    type: 'LOADING',
                    payload : {
                        loading : false
                    }
                });
            }
            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        });
    },
    //#endregion
    //#region FnListarCarpetasPersonalizadas
    FnListarCarpetasPersonalizadas : (body: DocumentsDeleteFolderModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, cargaLoading: boolean = true, cargaEmpresarialView: boolean = false) => {
        if(cargaLoading){
            dispatch({
                type: 'LOADING',
                payload : {
                    loading : true
                }
            });
        }

        body.typeFolder = TypeFolder.shared;

        GetListFoldersAndFiles_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                const respuestaPeticion : DocumentsListFoldersAndFilesDto = data;

                if(!body.id){
                    dispatch({
                        type: 'LIST_FOLDERS_FILES_EMPRESARIAL',
                        payload: respuestaPeticion
                    });
                }
                else{
                    dispatch({
                        type: 'LIST_FOLDERS_FILES',
                        payload: respuestaPeticion
                    });
                }
                
                dispatch({
                    type: 'LOADING',
                    payload : {
                        loading : false
                    }
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'LIST_FOLDERS_FILES_EMPRESARIAL',
                payload: documentsListFoldersAndFilesDto
            });

            if(cargaLoading){
                dispatch({
                    type: 'LOADING',
                    payload : {
                        loading : false
                    }
                });
            }
            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        });
    },
    //#endregion
    //#region FnCrearCarpeta
    FnCrearCarpeta : async (body: CreateFolderSharedFormModelComplete, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => { 
        return await GetCreateFolderShared_Post(body)
        .then(async (respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                return true;
            }
        })
        .catch(async (err) => {
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);           
            
            dispatch({
                type:'LOADING',
                payload: {
                    loading : false
                }
            });

            dispatch({
                type:'LOADING_SIDEBAR',
                payload: {
                    loading : false
                }
            });
        });
    },
    //#endregion
    //#region FnCargarArchivo
    FnCargarArchivo : (body: DocumentsCreateFileModel, companyId: string, typeFolder: number, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, userId: string) => {
        GetCreateFileShared_Post(body)
        .then(async (respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                let bodyAct : DocumentsDeleteFolderModel = {
                    peopleId: null,
                    companyId: companyId,
                    id : body?.folderId,
                    typeFolder,
                    userId
                };

                if(body.documentType > 0 && body.document.length > 0){
                    bodyAct.peopleId = body.documentType + '-' + body.document;
                }

                DocumentsAPI.FnListarCarpetasCompartidas(bodyAct, dispatch, firebase, history);
            }
        })
        .catch(async (err) => {
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        });
    },
    //#endregion
    //#region FnCargarMultiplesArchivos
    FnCargarMultiplesArchivos : async (body: DocumentsCreateFileModel, firebase: any, history: any) => {
        return await GetCreateFileShared_Post(body)
        .then(() => {
            return true;
        })
        .catch(async (err) => {
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
            return false;
        });
    },
    //#endregion
    //#region FnEditarCarpeta
    FnEditarCarpeta : async (body: DocumentsUpdatePropertiesModel, firebase: any, history: any, dispatch: Dispatch<ActionsGeneral>) => {
        return await GetUpdatePropertiesFolderShared_Post(body)
        .then(() => {
            return true;
        })
        .catch(async (err) => {     
            dispatch({
                type: 'LOADING',
                payload : {
                    loading : false
                }
            });

            dispatch({
                type: 'LOADING_SIDEBAR',
                payload : {
                    loading : false
                }
            });
            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
            return false;
        });
    },
    //#endregion
    //#region FnEliminarCarpeta
    FnEliminarCarpeta : async (body: DocumentsDeleteFolderModel, firebase: any, history: any) => {
        return await GetDeleteFolderShared_Post(body)
        .then(() => {
            return true;
        })
        .catch(async (err) => {            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
            return false;
        });
    },
    //#endregion
    //#region FnEliminarArhivo
    FnEliminarArhivo : async (body: DocumentsViewFileModel, firebase: any, history: any) => {
        return await GetDeleteFileShared_Get(body)
        .then(async (respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                return true;
            }
        })
        .catch(async (err) => {
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
            return false;
        });
    },
    //#endregion
    //#region FnVerArhivo
    FnVerArhivo : (body: DocumentsViewFileModel, firebase: any, history: any) => {
        GetViewFileShared_Get(body)
        .catch(async (err) => {            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        });
    },
    //#endregion
    //#region FnEditarArchivo
    FnEditarArchivo : async (body: DocumentsUpdatePropertiesFileModel, firebase: any, history: any) => {
        return await GetUpdatePropertiesFileShared_Post(body)
        .then(() => {
            return true;
        })
        .catch(async (err) => {            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
            return false;
        });
    },
    //#endregion
    //#region FnConsultaRemitentesPorCarpetaId
    FnConsultaRemitentesPorCarpetaId : (folderId: string, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetListReceiversByFolderId_Get(folderId)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: 'RECEIVERS_LIST_MODAL',
                    payload: {
                        abrir : true,
                        listado : data,
                        folderId : folderId
                    }
                })
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'RECEIVERS_LIST_MODAL',
                payload: {
                    abrir : false,
                    listado : [],
                    folderId : ''
                }
            })

            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        })
    },
    //#endregion
    //#region FnConsultaAdminsPorCompanyId
    FnConsultaAdminsPorCompanyId : (companyId: string, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetListUsersByCompanyId_Get(companyId)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: 'USERS_LIST_ADMINS',
                    payload: data
                })
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'USERS_LIST_ADMINS',
                payload: []
            });

            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        })
    },
    //#endregion
    //#region FnAbrirModalDestinatarios
    FnAbrirModalDestinatarios : (folderId: string, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        DocumentsAPI.FnConsultaRemitentesPorCarpetaId(folderId, dispatch, firebase, history);
    },
    //#endregion
    //#region FnEliminarRemitente
    FnEliminarRemitente : (body: DocumentsDestinatariosActionsModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetDeleteSenderFolder_Post(body)
        .then((respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                DocumentsAPI.FnConsultaRemitentesPorCarpetaId(body.folderId, dispatch, firebase, history);
            }
        })
        .catch(async (err) => {            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        });
    },
    //#endregion
    //#region FnAgregarRemitente
    FnAgregarRemitente : (body: DocumentsDestinatariosActionsModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetAddSenderFolder_Post(body)
        .then((respuesta) => {
            const {status} = respuesta;
            if(status === 200){
                DocumentsAPI.FnConsultaRemitentesPorCarpetaId(body.folderId, dispatch, firebase, history);
            }
        })
        .catch(async (err) => {
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        });
    },
    //#endregion
    //#region FnConsultaReporteVisualizacionArchivos
    FnConsultaReporteVisualizacionArchivos : (body: ReportViewerFilesModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any, propertiesFolder: DocumentsListFoldersAndFilesSubfoldersMiniDto | null) => {
        GetListReportFilesViewerEmployees_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: 'OPEN_MODAL_REPORTE_VISUALIZACIONES',
                    payload : {
                        open: true,
                        folder: propertiesFolder!,
                        dataInfo : data
                    }
                })
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'OPEN_MODAL_REPORTE_VISUALIZACIONES',
                payload: documentsReportViewerFilesEmployeesModal
            });

            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        })
    },
    //#endregion
    //#region FnListadoEmpleadosFiltro
    FnListadoEmpleadosFiltro : (body: EmployeeListByFilterModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        GetListEmployeesByCompanyFilter_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: 'LIST_EMPLOYEE_FILTER',
                    payload : data
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'LIST_EMPLOYEE_FILTER',
                payload : []
            });

            if(err?.status === 401){
                await firebase.auth().signOut();
                localStorage.removeItem('verificationNotify');
                localStorage.removeItem('TokenAPI');
                history.push('/');
            }
        })
    },
    //#endregion
    //#region FnActualizarEstadoArchivo
    FnActualizarEstadoArchivo : async (body: GestionEstadoNotasModel, firebase: any, history: any, dispatch: Dispatch<ActionsGeneral>) => {
        return await GetUpdateStateFile_Post(body)
        .then(() => {
            return true;
        })
        .catch(async (err) => {     
            dispatch({
                type: 'LOADING',
                payload : {
                    loading : false
                }
            });

            dispatch({
                type: 'LOADING_SIDEBAR',
                payload : {
                    loading : false
                }
            });
            
            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
            return false;
        });
    },
    //#endregion
    //#region Fn_LanzamientoCampanas
    Fn_LanzamientoCampanas : (body : DocumentsContadorIndependienteArchivosNuevosPropsModel, dispatch: Dispatch<ActionsGeneral>, firebase: any, history: any) => {
        dispatch({
            type: 'LIST_BELLS_FOLDERS',
            payload: []
        });

        GetBellsFolders_Post(body)
        .then((respuesta) => {
            const {status, data} = respuesta;
            if(status === 200){
                dispatch({
                    type: 'LIST_BELLS_FOLDERS',
                    payload: data
                });
            }
        })
        .catch(async (err) => {
            dispatch({
                type: 'LIST_BELLS_FOLDERS',
                payload: []
            });

            await DocumentsAPI.FnCatchExcepcionAuth(err, firebase, history);
        });
    },
    //#endregion
    //#region Fn_OrdenamientoTipoArchivo
    Fn_OrdenamientoTipoArchivo : (dispatch: Dispatch<ActionsGeneral>, subfolders: DocumentsListFoldersAndFilesDto) => {
        const {files} = subfolders?.subfolders;

        files.sort(function(a, b){
            if(a.stateFile > b.stateFile) { return 1; }
            if(a.stateFile < b.stateFile) { return -1; }
            return 0;
        });

        dispatch({
            type: 'LIST_FOLDERS_FILES',
            payload: subfolders
        });
    },
    //#endregion
    //#region Fn_OrdenamientoAlfabeticamente
    Fn_OrdenamientoAlfabeticamente : (dispatch: Dispatch<ActionsGeneral>, subfolders: DocumentsListFoldersAndFilesDto) => {
        const {subFolders, files} = subfolders?.subfolders;

        subFolders.sort(function(a, b){
            return a?.name?.localeCompare(b?.name, undefined, {
                numeric : true
            });
        });

        files.sort(function(a, b){
            return a?.name?.localeCompare(b?.name, undefined, {
                numeric : true
            });
        });

        dispatch({
            type: 'LIST_FOLDERS_FILES',
            payload: subfolders
        });
    },
    //#endregion
    //#region Fn_OrdenamientoUltimaModificacion
    Fn_OrdenamientoUltimaModificacion : (dispatch: Dispatch<ActionsGeneral>, subfolders: DocumentsListFoldersAndFilesDto) => {
        const {subFolders, files} = subfolders?.subfolders;

        subFolders.sort(function(a, b){
            if(a.createdAt > b.createdAt) { return -1; }
            if(a.createdAt < b.createdAt) { return 1; }
            return 0;
        });

        files.sort(function(a, b){
            if(a.createdAt > b.createdAt) { return -1; }
            if(a.createdAt < b.createdAt) { return 1; }
            return 0;
        });

        dispatch({
            type: 'LIST_FOLDERS_FILES',
            payload: subfolders
        });
    },
    //#endregion
    //#region Fn_ordenamientoGnal_ByFiltro
    Fn_ordenamientoGnal_ByFiltro : (filtro: number, dispatch: Dispatch<ActionsGeneral>, subfolders: DocumentsListFoldersAndFilesDto) => {
        switch(filtro){
            case 3:
                DocumentsAPI.Fn_OrdenamientoTipoArchivo(dispatch, subfolders);
            break;
            case 2:
                DocumentsAPI.Fn_OrdenamientoAlfabeticamente(dispatch, subfolders);
            break;
            default:
                DocumentsAPI.Fn_OrdenamientoUltimaModificacion(dispatch, subfolders);
            break;
        }
    },
    //#endregion
}

export default DocumentsAPI;