import { Button, Modal } from 'react-bootstrap';
import { Observable } from 'rxjs';
import { AlertDialogModalEvent } from '../service';
import { useHook } from './hook';

import './styles.scss';

export type AlertDialogProps = {
	service: Observable<AlertDialogModalEvent>;
	onAcceptClicked: () => void;
	onCancelClicked: () => void;
};

export const AlertDialog = (props: AlertDialogProps): JSX.Element => {
	const hook = useHook(props);

	return (
		<Modal show={hook.visible} centered id='planeo-alert-dialog'>
			<img src={hook.icon} alt='icono de alerta' />
			<div>
				<span className='title'>{hook.title}</span>
				<p className='message'>{hook.message}</p>
			</div>
			<div className='button-box'>
				{hook.isConfirm && (
					<Button
						className='btn-outline'
						onClick={() => {
							hook.setVisible(false);
							props.onCancelClicked();
						}}>
						CANCELAR
					</Button>
				)}

				<Button
					onClick={() => {
						hook.setVisible(false);
						props.onAcceptClicked();
					}}>
					ACEPTAR
				</Button>
			</div>
			{hook.footer && (
				<div>
					<p>{hook.footer}</p>
				</div>
			)}
		</Modal>
	);
};
