//#region Dependencias
import { BenchmarkList } from "@planeo-services/api/models/benchmarkModelsInterface";
import { Action_Benchmark } from "../ActionTypes/BenchmarkActions";

//#endregion

const sesionBenchmarkReducer = (state: BenchmarkList, action: Action_Benchmark): BenchmarkList => {
    switch(action.type){
        case 'BENCHMARK_LIST_AREAS':
            return {
                ...state,
                listAreas: action.payload
            };
        case 'BENCHMARK_LIST_ACADEMIC_BACKGROUNDS':
            return {
                ...state,
                listAcademicBackgrounds: action.payload
            };
        case 'BENCHMARK_LIST_COMPANY_SECTORS':
            return {
                ...state,
                listCompanySectors: action.payload
            };
        case 'BENCHMARK_LIST_COUNTRY_STATES':
            return {
                ...state,
                listCountryStates: action.payload
            };
        case 'BENCHMARK_LIST_RANGES_EXPERIENCE':
            return {
                ...state,
                listExperienceRanges: action.payload
            };
        case 'BENCHMARK_LIST_PROFESSIONS':
            return {
                ...state,
                listProfessions: action.payload
            };
        case 'BENCHMARK_RESULT_COMBINATION':
            return {
                ...state,
                resultCombination: action.payload
            };
        case 'BENCHMARK_RELEASE_REQUEST':
            return {
                ...state,
                releaseRequest: action.payload
            };
        default:
            return state;
    }
}

export default sesionBenchmarkReducer;