import { envsBase } from './env';
import { IEnvironments } from './interface-env';

const firebaseProdConfig = {
	apiKey: "AIzaSyAql7HVFFnJVym6DjuQBZvW0GqOufs_kjE",
	authDomain: "planeo-workforce-prod.firebaseapp.com",
	projectId: "planeo-workforce-prod",
	storageBucket: "planeo-workforce-prod.appspot.com",
	messagingSenderId: "489777782815",
	appId: "1:489777782815:web:42023fcf9a469981d0352a",
	measurementId: "G-YQGS02GGPT",
	databaseURL: 'https://planeo-workforce-prod-default-rtdb.firebaseio.com',	
	payrollBucket: 'planeo-payroll-bucket-prod',
	captcha: '6LdMrwIgAAAAAFTGf2OCynTEhuQR8oMTLj9IQmIE',
	amplitude_api_key: '758bd9dba6513302c12b39a8462f18ee',
	push_notifications: 'https://us-central1-planeo-workforce-prod.cloudfunctions.net/',
	urlPlaneoRedireccion: 'https://planeoapp.mareigua.co',
	urlPlaneoApiNewDocuments : 'https://us-central1-planeo-workforce-prod.cloudfunctions.net/ApiDocumentsNewVersion/',
	urlPlaneoApiTags : 'https://us-central1-planeo-workforce-prod.cloudfunctions.net/ApiTags/',
	urlPlaneoApiUsersManagement : 'https://us-central1-planeo-workforce-prod.cloudfunctions.net/ApiUsersManagement/',
	urlPlaneoApiUsers : 'https://us-central1-planeo-workforce-prod.cloudfunctions.net/ApiUsersServices/',
	urlPlaneoApiSelfEmployees : 'https://us-central1-planeo-workforce-prod.cloudfunctions.net/ApiSelfEmployeesMiddleware/'
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	...envsBase(firebaseProdConfig),
	isProduction: false,
} as IEnvironments;
