//#region Dependencias
import {Row, Col} from 'react-bootstrap';
import BannerIzquierdoRegistrarEmpresa from './BannerIzquierdoRegistrarEmpresa';
//#endregion

interface LayoutLoginProps {
    componenteHijo : JSX.Element
}

const LayoutLogin = (props: LayoutLoginProps) => {
    const {componenteHijo} = props;
    //#region Render
    return (
        <Row>
            <BannerIzquierdoRegistrarEmpresa />
            <Col
                sm={12}
                md={12} 
                lg={12}
                xl={5}
                xs={12}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#FFF',
                    alignItems: 'center'
                }}
            >
                {
                    componenteHijo
                }
            </Col>
        </Row>
    )
    //#endregion
}

export default LayoutLogin;