import { FolderTypeEnum } from '../enums/folder-type.enum';
import { CompanyFolder } from '../models';

import fbase from 'firebase/app';

export const DefaultCompanyFolders: Omit<CompanyFolder, 'id'>[] = [
	{
		name: 'Desprendibles de nómina',
		type: FolderTypeEnum.employee,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Certificados de ingresos y retenciones',
		type: FolderTypeEnum.employee,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Contrato',
		type: FolderTypeEnum.employee,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Manuales corporativos',
		type: FolderTypeEnum.general,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
	{
		name: 'Reglamento de trabajo',
		type: FolderTypeEnum.general,
		rootReference: null,
		parentReference: null,
		isDefault: true,
		createdAt: fbase.firestore.Timestamp.fromDate(new Date()),
	},
];

export const doctypes = [
	{ id: 11, name: 'Registro civil' },
	{ id: 12, name: 'Tarjeta de identidad' },
	{ id: 13, name: 'Cédula de ciudadanía' },
	{ id: 21, name: 'Tarjeta de extranjería' },
	{ id: 22, name: 'Cédula de extranjería' },
	{ id: 31, name: 'NIT' },
	{ id: 41, name: 'Pasaporte' },
	{ id: 42, name: 'Documento de identificación extranjero' },
	{ id: 47, name: 'Permiso Especial de Permanencia (PEP)' },
	{ id: 50, name: 'NIT de otro país' },
	{ id: 91, name: 'Número Único de Identificación Personal (NUIP)' },
];