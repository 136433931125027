//#region Dependencias
import {Form} from 'react-bootstrap';
//#endregion

export interface TextBoxDesignProps {
    titulo : string;
    id : string;
    cantidad : number;
    onChange : React.ChangeEventHandler;
    tipo: string;
    error: string | undefined;
    defaultValue ?: string;
    disabled ?: boolean;
}

const TextBoxDesign = (props: TextBoxDesignProps) => {
    const {id, tipo, cantidad, error, onChange, defaultValue = '', disabled = false, titulo} = props;

    //#region Render
    return (
        <Form.Group>
            <Form.Label style={{fontSize:'12px', marginTop: 28, marginBottom:7}}>{titulo}</Form.Label>
            <Form.Control
                id={id}
                type={tipo}
                maxLength={cantidad}
                isInvalid={error ? true : false}
                onChange={onChange}
                autoComplete='nope'
                style={{
                    border: '1px solid #0000001F'
                }}
                defaultValue={defaultValue}
                disabled={disabled}
                placeholder={titulo}
            />
            {
                error
                &&
                <Form.Control.Feedback type='invalid'>
                    {error}
                </Form.Control.Feedback>
            }
        </Form.Group>
    )
  //#endregion
}

export default TextBoxDesign;