import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { logo_white_small, login_background } from '@planeo-assets';
import usePasswordToggle from '@planeo-components/usePassWordToggle';
import PopOverInfo from '@planeo-components/PopOverInfo';
import { useHook } from './hook';

export const ChangePasswordPartial = (): JSX.Element => {
	const [InfoIcon] = PopOverInfo('Crear una contraseña segura de 8 caracteres como mínimo, con combinación entre mayúsculas, minúsculas y números');
	const [InputType, IconType] = usePasswordToggle();

	const hook = useHook();

	return (
		<Modal show={hook.visible} centered id='planeo-password-recovery' animation={false}>
			<div className='main-logo' style={{ backgroundImage: `url(${login_background})` }}>
				<img src={logo_white_small} alt='Planeo logo' />
			</div>

			<Form onSubmit={hook.handleSubmit(hook.onSubmit)} className='recovery-form'>
				<Form.Group className='text-centered'>
					<span className='font-20 title'>Restaurar Contraseña</span>
				</Form.Group>

				<Form.Group className='input-icon'>
					<Form.Label>Nueva contraseña *</Form.Label>
					<Form.Control
						{...hook.register('password')}
						className={hook.errors.password && 'validation-error'}
						type={String(InputType)}
						placeholder=''
						autoComplete='off'
					/>
					<span className='icon'>{InfoIcon}</span>
					<Form.Text>{hook.errors.password?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='input-icon'>
					<Form.Label>Confirmar contraseña *</Form.Label>
					<Form.Control
						{...hook.register('passwordConfirm')}
						className={hook.errors.passwordConfirm && 'validation-error'}
						type={String(InputType)}
						placeholder=''
					/>
					<span className={'icon'}>{IconType}</span>
					<Form.Text>{hook.errors.passwordConfirm?.message}</Form.Text>
				</Form.Group>

				<Form.Group className='buttons-group'>
					<Button type='submit' disabled={!hook.isValid}>
						{hook.isSubmiting ? <Spinner className='spinner' animation='border' role='status' /> : 'RESTAURAR'}
					</Button>
					<Button className='btn-basic' type='button' onClick={hook.handleCancelClicked}>Cancelar</Button>
				</Form.Group>
			</Form>
		</Modal>
	);
};
