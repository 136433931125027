//#region Dependencias
import { ActionsGeneral } from "../ActionTypes"
import {NewTypes} from '../ActionTypes/Actions_Interfaces';
import { Action_Benchmark } from "../ActionTypes/BenchmarkActions";
import { Action_Documents } from "../ActionTypes/DocumentsActions";
import { Action_Notifications } from "../ActionTypes/NotificacionesActions";
import sesionBenchmarkReducer from "./sesionBenchmarkReducer";
import sesionDocumentsReducer from "./sesionDocumentsReducer";
import sesionNotificationsReducer from "./sesionNotificationsReducer";
//#endregion

export const mainReducer = ({
    sesionDocuments,
    sesionNotifications,
    sesionBenchmark
}: NewTypes, action: ActionsGeneral) => {
    return {
        sesionDocuments : sesionDocumentsReducer(sesionDocuments, action as Action_Documents),
        sesionNotifications : sesionNotificationsReducer(sesionNotifications, action as Action_Notifications),
        sesionBenchmark : sesionBenchmarkReducer(sesionBenchmark, action as Action_Benchmark)
    }
}