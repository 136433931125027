import { Modal } from 'react-bootstrap';
import { useHook } from './hook';
import { LoginForm } from '../partials/form';
import LogoMareigua from '@planeo-media/Logo_black_Small.svg';
import './styles.scss';

export interface LoginModalProps {
	visible: boolean;
	onHide: () => void;
	onclickRecovery: (s: boolean) => void;
}

export const LoginModal = (props: LoginModalProps): JSX.Element => {
	const hook = useHook();

	return (
		<Modal id='planeo-login-modal' show={props.visible && !hook.needMailValidation} centered onHide={props.onHide}>
			<div className='main-logo'>
				<img src={LogoMareigua} alt='Logo Mareigua' />
			</div>
			<LoginForm
				onNeedMailValidation={() => {
					hook.setneedMailValidation(true);
				}}
				onPasswordForgot={() => {
					props.onclickRecovery(true);
				}}
			/>

			<div className='modal-footer'>
				<span>¿Aún no tienes una cuenta?</span>
				<span className='link' onClick={props.onHide}>
					Regístrate
				</span>
			</div>
		</Modal>
	);
};
