const RefactoringAPI = {
    //#region Fn_ObtenerExtension
    Fn_ObtenerExtension : (nombreArchivo: string) => {
        if(nombreArchivo?.length > 0){
            return nombreArchivo?.split('.')?.pop()?.toLowerCase();
        }
        return '';
    },
    //#endregion
    //#region Fn_ObtenerConversionArrayString
    Fn_ObtenerConversionArrayString : (array: any) => {
        if(Array.isArray(array) && array.length > 0){
            const salida = array.join(',');
            return salida;
        }

        return '';
    }
    //#endregion
}

export default RefactoringAPI;